import { render, staticRenderFns } from "./productos.vue?vue&type=template&id=60987cd0&scoped=true"
import script from "./productos.vue?vue&type=script&lang=js"
export * from "./productos.vue?vue&type=script&lang=js"
import style0 from "./productos.vue?vue&type=style&index=0&id=60987cd0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60987cd0",
  null
  
)

export default component.exports